import React, { createContext, useState, useCallback, useMemo } from "react";
import CookieConsent from "react-cookie-consent";
import { useTheme } from "@mui/material/styles";

import i18next, { t } from "i18next";
import { commonTrans as enCommonTrans } from "../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../locales/pl/common";

i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

export const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(false);
  const theme = useTheme();

  const setGtagConsent = (consentType, consentValue) => {
    if (typeof window.gtag !== "undefined") {
      window.gtag("consent", consentType, {
        ad_storage: consentValue,
        analytics_storage: consentValue,
        ad_user_data: consentValue,
        ad_personalization: consentValue,
      });
    }
  };

  const handleAccept = useCallback(() => {
    localStorage.setItem("gtagConsent", "granted");
    setGtagConsent("update", "granted");
    setConsent(true);
  }, []);

  const handleDecline = useCallback(() => {
    localStorage.setItem("gtagConsent", "denied");
    setGtagConsent("update", "denied");
    setConsent(false);
  }, []);

  const buttonAcceptColor = theme.palette.mode === "dark" ? "black" : "white";

  const contextValue = useMemo(() => ({ consent, handleAccept, handleDecline }), [consent, handleAccept, handleDecline]);

  const consentStyles = {
    zIndex: 9999,
    fontFamily: theme.typography.fontFamily,
  };

  const buttonStyles = {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary.main,
    color: buttonAcceptColor,
    borderRadius: "4px",
  };

  const declineButtonStyles = {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.highlighted.main,
    color: theme.palette.text.primary,
    borderRadius: "4px",
  };

  return (
    <CookieConsentContext.Provider value={contextValue}>
      {children}
      {!consent && ( // Pokazuje baner tylko, jeśli zgoda nie została udzielona
        <CookieConsent
          location="bottom"
          buttonText={t("common:accept_all", "Accept all")}
          enableDeclineButton
          declineButtonText={t("common:reject_all", "Reject all")}
          onAccept={handleAccept}
          onDecline={handleDecline}
          style={consentStyles}
          buttonStyle={buttonStyles}
          declineButtonStyle={declineButtonStyles}
        >
          {t(
            "common:cookie_text",
            "We use cookies to make the site work better, but also to see how you interact with it. " +
            'We will only use cookies if you allow us to do so by clicking "Accept Cookies".'
          )}
        </CookieConsent>
      )}
    </CookieConsentContext.Provider>
  );
};
