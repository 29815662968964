import React from "react";

const GtagScript = () => {
  const TAG_ID = process.env.GATSBY_GTM_MEASUREMENT_ID;

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            if(localStorage.getItem('gtagConsent') === 'granted') {
              gtag('consent', 'default', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
              });
            } else {
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
              });
            }
          `,
        }}
      />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${TAG_ID}');
          `,
        }}
      />
    </>
  );
};

export default GtagScript;